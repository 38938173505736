<template>
  <li class="faq d-flex flex-row clickable" @click="showAnswer">
    <div class="" >
      <div class='question'><font-awesome-icon class="questionMark" :icon="['fas', 'feather']"/>{{faq.q}}</div>
      <div class='answer' v-show="showA" v-html="faq.a">
      </div>
    </div>
  </li>

</template>

<script>
export default {
  name: 'FAQ',
  props: {
    faq: {},
  },
  data(){
    return{
      showA:false,
    }
  },
  computed:{

  },
  methods:{
    showAnswer:function(){
      this.$clickSound.play();
      this.showA = !this.showA
    }
  }

}
</script>

<style scoped>
  .questionMark{
    color:#0dcaf0;
    margin-right:15px;

  }

  .answer{
    margin:10px;
    padding:10px;
    background-color: #f1efef;
  }

</style>
