<template>
  <div class="mainContainer container" style="">


    <div style="display:flex;">
      <back-button />
      <h5 class="pageTitle">FAQs and Support</h5>
    </div>
    <hr>

    <div class="contentBody">
      <div class="section">
        <h6>FAQs</h6>
        <hr>
        <ul>
          <FAQ class="faq" v-for="(faq,idx) in faqs" :key="idx" :faq="faq"></FAQ>
        </ul>
      </div>
      <div class="section">
        <h6>Support</h6>
        <hr>
        <button class="btn btn-warning" @click="this.$root.gotoRoute('/settings/contactus')">Contact Us</button>
        <br><br>
        Or Send an email to hello@goblinbank.com with any questions, suggestions and feedback.<br><br>
      </div>


    </div>
  </div>
</template>

<script>
import FAQ from "@/components/FAQ";


export default {
  name: 'FAQs',
  components: {
    FAQ
  },
  data(){
    return{
      faqs:[]
    }
  },
  methods:{
    getFAQs(){
      this.axios
          .get(this.$hostname + '/getdata/?data=faqs',{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.faqs = response.data;

          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

  },
  mounted() {
    document.title = "FAQs";
    this.getFAQs();
  }
}
</script>

<style scoped>
.contentBody{
  margin:10px;
  padding:10px;
  font-size:12px;
}
.section{
  margin-bottom:50px;
}
.faq{
  margin-bottom:10px;
}
.link{
  color:#0dcaf0;
  text-decoration: underline;
}


</style>